import styles from './Product.module.scss';
import { memo } from 'react';
import { UseSanaTexts, SimpleText } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { Placeholder } from 'components/primitives/placeholders';

//TICKET 156337 - 3.16. Product which are Available on demand
const ProductStockOnDemand = () => {

  const stockValuePlaceholder = <Placeholder className={styles.stockValuePlaceholder} />;

  return (
    <UseSanaTexts options={["StockText_AvailableOnDemand"]} placeholder={stockValuePlaceholder}>
      {([productOnDemandText]) => {
        return (productOnDemandText && (
          <>
            <div className={styles.avilableOnDemandBody}>
      {/*   <span className={styles.name}>*/}
      {/*        <SimpleText textKey="Avilable_On_Demand_label"  />*/}
      {/*</span>*/}
          <span className={`${styles.availableOnDemand}`}>
            {makeRichText(productOnDemandText)}
              </span>
              </div>
            </>
        ));
      }}
    </UseSanaTexts>
  );
};


export default memo(ProductStockOnDemand);
