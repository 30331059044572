//191777: tarif client
import styles from './ProductPrice.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { renderHTML, makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getFormatPrice, formatAsPercentage, getFormatVolumePrice } from 'utils/format';
import { usePriceModel } from 'utils/product';
import { useCurrencyInfo } from 'utils/hooks';

const ProductPriceForGroupPages = ({ salesPrice, basePrice }) => {

  const currencyInfo = useCurrencyInfo();
  const { price, actionPrice, discountAmount, discountPercentage } = usePriceModel(salesPrice, basePrice);
  const formatAsPrice = currencyInfo && getFormatVolumePrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    let numberToFix = 2;
    if (price.countDecimals() > 2)
      numberToFix = 3;

    return renderHTML(formatPrice(formatAsPrice(price.toFixed(numberToFix)), currencyInfo));
  }, [currencyInfo, price]);

  const formattedActionPrice = currencyInfo && actionPrice
    && actionPrice !== price && formatAsPrice(actionPrice);

  const { texts: [
    priceLbl,
    actionPriceLbl,
    discountLbl,
    discountAmountTemplate,
    discountPercentTemplate,
  ], loaded } = useSanaTexts([
    'Price',
    'ActionPrice_Label',
    'ActionPrice_Discount_Label',
    'ActionPrice_DiscountAmount',
    'ActionPrice_DiscountPercentage',
  ]);

  if (!loaded || formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  return (
    <>
      {(!!(formattedActionPrice || discountAmount || discountPercentage)) && (
        <Col xs="auto" className="price-block price-list" role="group">
          {!!actionPrice && (
            <div className={`${styles.actionPrice} action-price`} title={actionPriceLbl}>
              <i className="visually-hidden">{actionPriceLbl} {currencyInfo.symbol + actionPrice}</i>
              <span aria-hidden suppressHydrationWarning>
                {formattedActionPrice}
              </span>
            </div>
          )}
          {!!discountAmount && (
            <div className={`${styles.discountAmount} discount-amount`} title={discountLbl}>
              <i className="visually-hidden">{discountLbl} {currencyInfo.symbol + discountAmount}</i>
              <span aria-hidden suppressHydrationWarning>
                {makeSimpleText(discountAmountTemplate, [formatAsPrice(discountAmount)])}
              </span>
            </div>
          )}
          {!!discountPercentage && (
            <div className={`${styles.discountPercentage} discount-percentage`} title={discountLbl}>
              <i className="visually-hidden">{discountLbl} {discountPercentage + '%'}</i>
              <span aria-hidden>
                {makeSimpleText(discountPercentTemplate, [formatAsPercentage(discountPercentage, currencyInfo, false, 2)])}
              </span>
            </div>
          )}
        </Col>
      )}
      <Col xs="auto" className="price-block" title={priceLbl}>
        <i className="visually-hidden">{priceLbl} {currencyInfo.symbol + price}</i>
        <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
          {formattedPrice}
        </span>
      </Col>
    </>
  );
};

ProductPriceForGroupPages.propTypes = {
  salesPrice: PropTypes.number,
  basePrice: PropTypes.number,
};

export default memo(props => {
  if (props.salesPrice === null)
    return null;

  if (typeof props.salesPrice === 'undefined')
    return <Placeholder className="price-block product-price-placeholder" />;

  return <ProductPriceForGroupPages {...props} />;
});

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}

Number.prototype.countDecimals = function () {

  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

  var str = this.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
}
