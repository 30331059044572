//[156335] [VOLUME] [Siremballage] 3.14. Level 3 product group pages

import styles from './Product.module.scss';
import btnStyles from '../../objects/productGroup/ProductGroup.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomerType } from 'behavior/user/constants';
import { UseSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { makeRichText, makeSimpleText } from 'utils/render';
import { GroupStockIcon } from 'components/primitives/icons';
import Tooltip from 'components/primitives/tooltip';

//Ticket 225756: Article non disponible mais pastille verte
const GroupProductStock = ({ inventory, stockLevels, customerType, onRendered, forVariant, isAvailableOnDemand, outOfStockThreshold }) => {
  const { outOfStock, lowStock, maxStockNumber } = stockLevels;
  let className = '';
  let sanaTextsToLoad = [];

  const stockValuePlaceholder = <Placeholder className={styles.stockValuePlaceholder} />;

  if (typeof inventory !== 'undefined') {
    let textKey = 'StockText_';

    if (isAvailableOnDemand) {
      textKey += 'AvailableOnDemand';
      className = styles.availableOnDemand;
    }
    else {
      //Ticket 225756: Article non disponible mais pastille verte
      if (inventory <= outOfStockThreshold) {
        textKey += 'OutOfStock';
        className = styles.outStock;
      }
      else if (inventory > lowStock) {
        textKey += 'InStock';
        className = styles.inStock;
      }
      else {
        textKey += 'LowStock';
        className = styles.lowStock;
      }

      if (!customerType || customerType === CustomerType.B2C)
        textKey += '_B2C';
      else
        textKey += '_B2B';

      if (forVariant)
        textKey += '_Variant';
    }

    sanaTextsToLoad = [textKey];

    if (maxStockNumber && maxStockNumber < inventory)
      sanaTextsToLoad.push('StockText_MaxStockNumber');

  } else {
    return stockValuePlaceholder;
  }


  return (
    <UseSanaTexts options={sanaTextsToLoad} placeholder={stockValuePlaceholder}>
      {([stockText, maxStockNumberText]) => {
        onRendered && onRendered();
        return (stockText && (
          <span
            title={makeRichText(stockText.replace('[STOCKAMOUNT]', maxStockNumberText ? maxStockNumberText : inventory), [maxStockNumber])}
            className={`${styles.stockValue} ${className} ${btnStyles.stockLevel}`}
          >
            <GroupStockIcon />
          </span>
        ));
      }}
    </UseSanaTexts>
  );

};

GroupProductStock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
  additionalClass: PropTypes.string,
  customerType: PropTypes.string,
  onRendered: PropTypes.func,
  forVariant: PropTypes.bool,
  isAvailableOnDemand: PropTypes.bool,
};

export default connect(({ user: { customerType } }) => ({ customerType }))(GroupProductStock);
